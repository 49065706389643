exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-cet-js": () => import("./../../../src/pages/about-cet.js" /* webpackChunkName: "component---src-pages-about-cet-js" */),
  "component---src-pages-about-cgpu-js": () => import("./../../../src/pages/about-cgpu.js" /* webpackChunkName: "component---src-pages-about-cgpu-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-placement-js": () => import("./../../../src/pages/placement.js" /* webpackChunkName: "component---src-pages-placement-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-recruiters-js": () => import("./../../../src/pages/recruiters.js" /* webpackChunkName: "component---src-pages-recruiters-js" */)
}

